<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'register' }" class="no-caps" :ripple="false">
            {{ $t('customer') }}
            <!-- <v-icon size="24">mdi-account-circle</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'register-business' }" class="no-caps" :ripple="false">
            {{ $t('business') }}
            <!-- <v-icon size="24">mdi-briefcase-account</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent;" class="mt-8">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <ValidationObserver ref="form1" v-slot="{ invalid }">
                <v-form 
                  :model="form1" 
                  @submit.prevent="submit('form1')"
                  autocomplete="off"
                  method="post"
                >
                  <h1 class="fg--text display-2 font-weight-medium text--primary mb-2">{{ $t('registration_business_title') }}</h1>
                  <p class="mb-8 fg--text subtitle-1 text--secondary">{{ $t('registration_business_head') }}</p>
                  <v-alert
                    :value="form1.hasError && !form1.success"
                    type="error"
                    tile
                    class="mb-4"
                  >
                    <span v-if="form1.error == 'registration_validation_error'">{{ $t('server_error') }}</span>
                    <span v-else>{{ $t('correct_errors') }}</span>
                  </v-alert>
                  <x-text-field 
                    v-model="form1.business_name"
                    ref="form1.business_name"
                    id="form1.business_name"
                    :label="$t('business_name')"
                    :placeholder="$t('business_name')"
                    :name="$t('business_name')"
                    rules="required|min:2|max:32"
                  />
                  <x-autocomplete
                    v-model="form1.currency"
                    ref="form1.currency"
                    id="form1.currency"
                    :items="currencies"
                    item-value="0" 
                    item-text="1"
                    :label="$t('currency')"
                    :placeholder="$t('currency')"
                    :name="$t('currency')"
                    rules="required"
                  />
                  <v-row>
                    <v-col cols="12" sm="6">
                      <x-text-field 
                        v-model="form1.first_name"
                        ref="form1.first_name"
                        id="form1.first_name"
                        :label="$t('first_name')"
                        :placeholder="$t('first_name')"
                        :name="$t('name')"
                        rules="required|min:1|max:32"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <x-text-field 
                        v-model="form1.last_name"
                        ref="form1.last_name"
                        id="form1.last_name"
                        :label="$t('last_name')"
                        :placeholder="$t('last_name')"
                        :name="$t('last_name')"
                        rules="required|min:1|max:32"
                      />
                    </v-col>
                  </v-row>
                  <x-text-field 
                    type="email"
                    v-model="form1.email"
                    ref="form1.email"
                    id="form1.email"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    :name="$t('email')"
                    rules="required|max:64|email"
                  />
                  <x-password
                    v-model="form1.password"
                    ref="form1.password"
                    id="form1.password"
                    :label="$t('password')"
                    :placeholder="$t('password')"
                    :name="$t('password')"
                    rules="required|min:8|max:24"
                  />
                  <v-btn color="primary" class="no-caps mt-4" rounded x-large depressed block :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('sign_up') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="caption mt-8 px-0 px-md-8 text--secondary">
          <i18n path="sign_up_consent">
            <template #terms_and_conditions>
              <router-link :to="{name: 'terms'}" class="text--secondary">{{ $t('terms_and_conditions') }}</router-link>
            </template>
            <template #privacy_policy>
              <router-link :to="{name: 'privacy'}" class="text--secondary">{{ $t('privacy_policy') }}</router-link>
            </template>
            <template #about>
              <router-link :to="{name: 'about'}" class="text--secondary">{{ $t('about') }}</router-link>
            </template>
          </i18n>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    currencies: [],
    form1: {
      loading: false,
      terms: '',
      business_name: '',
      currency: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      locale: '',
      timezone: '',
      hasError: false,
      error: '',
      success: false
    }
  }),
  created () {
    this.form1.locale = Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.form1.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null

    this.axios
      .get('/localization/currencies', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.currencies = this.$_.toPairs(response.data)
      })

  },
  methods: {
    async submit (formName) {
      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.$auth.register({
        data: {
          language: this.$i18n.locale,
          type: 'business',
          business_name: form.business_name,
          currency: form.currency,
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          password: form.password,
          locale: form.locale,
          timezone: form.timezone,
          terms: form.terms
        },
        success: function () {
          form.success = true

          this.$auth.login({
            rememberMe: true,
            fetchUser: true,
            params: {
              locale: this.$i18n.locale,
              email: form.email,
              password: form.password,
              remember: true
            },
            success () {
              // Handle redirection
              let redirectTo
              if (this.$auth.user().role === 1) redirectTo = 'admin.dashboard'
              if (this.$auth.user().role === 2) redirectTo = 'customer..earn'
              if (this.$auth.user().role === 3) redirectTo = 'business.dashboard'
              if (this.$auth.user().role === 4) redirectTo = 'staff.dashboard'
              // Redirect
              this.$router.push({name: redirectTo})
            }
          })
        },
        error: function (error) {
          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          if (errors.length === 0) {
            form.hasError = true
          }

          form.loading = false
        }
      })
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        let index = val.split('-')
        this.tabImg = this.tabImgs[index[1]]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      }
    }
  },
}
</script>
